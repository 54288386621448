import { SortOrder } from '@solidant/spool-v2-fe-lib'
import { CreateMetaVaultVaults } from '@spool.fi/spool-v2-sdk'
import { memo, useMemo, useReducer } from 'react'

import { CreateMetavaultContext } from '@/store/createMetavault/createMetavault.context'
import createMetavaultReducer from '@/store/createMetavault/createMetavault.reducer'
import { initialState } from '@/store/createMetavault/createMetavault.state'
import { CreateMetavaultActionTypes } from '@/store/createMetavault/createMetavault.types'

import { RCNode } from '@/types/index'
import { SortType } from '@/types/table'

export const CreateMetavaultProvider = memo(({ children }: RCNode) => {
  const [createMetavault, dispatch] = useReducer(
    createMetavaultReducer,
    initialState
  )

  const setCurrentStep = (currentStep: number) => {
    dispatch({
      type: CreateMetavaultActionTypes.SET_CURRENT_STEP,
      payload: currentStep,
    })
  }

  const setStepCompletedStatus = (stepNumber: number, status: boolean): void =>
    dispatch({
      type: CreateMetavaultActionTypes.SET_STEP_COMPLETED,
      payload: { stepNumber, status },
    })

  const setAsset = (asset: string) => {
    dispatch({
      type: CreateMetavaultActionTypes.SET_ASSET,
      payload: asset,
    })
  }

  const setSelectedVaults = (selectedVaults: CreateMetaVaultVaults[]) => {
    dispatch({
      type: CreateMetavaultActionTypes.SET_SELECTED_VAULTS,
      payload: selectedVaults,
    })
  }

  const handleSortClick = (
    key: keyof CreateMetaVaultVaults,
    vaultsSort: SortType<CreateMetaVaultVaults>
  ) => {
    dispatch({
      type: CreateMetavaultActionTypes.SET_VAULTS_SORT,
      payload: {
        key,
        direction:
          vaultsSort.key === key
            ? vaultsSort.direction === SortOrder.ASC
              ? SortOrder.DESC
              : SortOrder.ASC
            : SortOrder.ASC,
      },
    })
  }

  const setSelectedVaultsAllocations = (
    selectedVaultsAllocations: number[]
  ) => {
    dispatch({
      type: CreateMetavaultActionTypes.SET_SELECTED_VAULTS_ALLOCATIONS,
      payload: selectedVaultsAllocations,
    })
  }

  const setVaultName = (vaultName: string) => {
    dispatch({
      type: CreateMetavaultActionTypes.SET_VAULT_NAME,
      payload: vaultName,
    })
  }

  const setVaultSymbol = (vaultSymbol: string) => {
    dispatch({
      type: CreateMetavaultActionTypes.SET_VAULT_SYMBOL,
      payload: vaultSymbol,
    })
  }

  const resetAll = () => {
    dispatch({
      type: CreateMetavaultActionTypes.RESRET_ALL,
    })
  }

  const setSearchingString = (searchString: string) => {
    dispatch({
      type: CreateMetavaultActionTypes.SET_SEARCH_STRING,
      payload: searchString,
    })
  }

  const setMetavaultId = (metavaultId: string) => {
    dispatch({
      type: CreateMetavaultActionTypes.SET_METAVALUT_ID,
      payload: metavaultId,
    })
  }

  const setNewlyAddedVaults = (newlyAddedVaults: CreateMetaVaultVaults[]) => {
    dispatch({
      type: CreateMetavaultActionTypes.SET_NEWLY_ADDED_VAULTS,
      payload: newlyAddedVaults,
    })
  }

  const contextValue = useMemo(() => {
    return {
      ...createMetavault,
      setCurrentStep,
      setStepCompletedStatus,
      setAsset,
      setSelectedVaults,
      handleSortClick,
      setSelectedVaultsAllocations,
      setVaultName,
      setVaultSymbol,
      resetAll,
      setSearchingString,
      setMetavaultId,
      setNewlyAddedVaults,
    }
  }, [createMetavault])

  return (
    <CreateMetavaultContext.Provider value={contextValue}>
      {children}
    </CreateMetavaultContext.Provider>
  )
})
